import React, { useState } from 'react';
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "./layout.css"

export default function Layout({ children }) {

  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  
  const [dropdownOpenNewCars, setDropdownOpenNewCars] = useState(false);
  const [dropdownOpenCarMatters, setDropdownOpenCarMatters] = useState(false);
  const toggleNewCars = () => setDropdownOpenNewCars(!dropdownOpenNewCars);
  const toggleCarMatters = () => setDropdownOpenCarMatters(!dropdownOpenCarMatters);

  return (
    <div>
        <Navbar color="dark" dark expand="lg">
            <NavbarBrand href="/">OSCARS</NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar>
                    <Dropdown nav isOpen={dropdownOpenNewCars} toggle={toggleNewCars}>
                        <DropdownToggle nav caret>New cars</DropdownToggle>
                        <DropdownMenu className="dropdown-menu multi-column columns-4">
                        <div>
                            <div class="row mt-3 mb-3 ml-3 mr-3">
                                <div class="col-md-3"><Link to="/carsnewmake?make=alfa">Alfa Romeo</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=alpine">Alpine</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=aston">Aston Martin</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=audi">Audi</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=bentley">Bentley</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=bmw">BMW</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=byd">BYD</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=citroen">Citroen</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=ds">DS</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=ferrari">Ferrari</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=fiat">Fiat</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=ford">Ford</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=golden">Golden Dragon</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=hino">Hino</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=honda">Honda</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=hyundai">Hyundai</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=infiniti">Infiniti</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=land">Land Rover</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=lexus">Lexus</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=lotus">Lotus</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=maserati">Maserati</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=maxus">Maxus</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mazda">Mazda</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mclaren">McLaren</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mercedes">Mercedes-Benz</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mg">MG</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mini">Mini</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=mitsubishi">Mitsubishi</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=nissan">Nissan</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=opel">Opel</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=perodua">Perodua</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=peugeot">Peugeot</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=porsche">Porsche</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=renault">Renault</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=rolls">Rolls-Royce</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=seat">SEAT</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=skoda">Skoda</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=ssangyong">Ssangyong</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=subaru">Subaru</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=suzuki">Suzuki</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=tesla">Tesla</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=toyota">Toyota</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=volkswagen">Volkswagen</Link></div>
                                <div class="col-md-3"><Link to="/carsnewmake?make=volvo">Volvo</Link></div>
                            </div>
                        </div>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem><NavLink href="/carsused">Used cars</NavLink></NavItem>
                    <NavItem><NavLink href="/carsrental">Rental cars</NavLink></NavItem>
                    <NavItem><NavLink href="/carssell">Sell cars</NavLink></NavItem>
                    <NavItem><NavLink href="/carsreview">Car reviews</NavLink></NavItem>
                    <Dropdown nav isOpen={dropdownOpenCarMatters} toggle={toggleCarMatters}>
                        <DropdownToggle nav caret>Car matters</DropdownToggle>
                        <DropdownMenu>
                            <ul class="multi-column-dropdown">
                                    <li><Link class="dropdown-item" to="/carsnew">Products</Link></li>
                                    <li><Link class="dropdown-item" to="/carsnew">Accessories</Link></li>
                                    <li><Link class="dropdown-item" to="/carsnew">Workshops</Link></li>
                                    <li><Link class="dropdown-item" to="/carsnew">Insurance</Link></li>
                                    <li><Link class="dropdown-item" to="/carsnew">Road Tax</Link></li>
                                    <li><Link class="dropdown-item" to="/carsnew">Forum</Link></li>
                            </ul>
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
            </Collapse>
        </Navbar>

        {children}
        
        <footer class="container-fluid py-5 bg-dark">
            <div class="row">
                <div class="col-12 col-md">
                    <h5 class="text-white">OSCARS</h5>
                    <small class="d-block mb-3 text-muted">&copy; 2017-2020</small>
                </div>
                <div class="col-6 col-md">
                    <h6 class="text-light">Services</h6>
                    <ul class="list-unstyled text-small">
                        <li><Link to="/carsnew" class="text-muted">New cars</Link></li>
                        <li><Link to="/carsused" class="text-muted">Used cars</Link></li>
                        <li><Link to="/carsrental" class="text-muted">Rental cars</Link></li>
                        <li><Link to="/carssell" class="text-muted">Sell cars</Link></li>
                        <li><Link to="/carsreview" class="text-muted">Car reviews</Link></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h6 class="text-light">Other Matters</h6>
                    <ul class="list-unstyled text-small">
                        <li><Link to="/carsroadtax" class="text-muted">Road Tax</Link></li>
                        <li><Link to="/carsinsurance" class="text-muted">Insurance</Link></li>
                        <li><Link to="/carsproducts" class="text-muted">Products</Link></li>
                        <li><Link to="/carsaccessories" class="text-muted">Accessories</Link></li>
                        <li><Link to="/carsworkshops" class="text-muted">Workshops</Link></li>
                        <li><Link to="/carsforum" class="text-muted">Forum</Link></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h6 class="text-light">About</h6>
                    <ul class="list-unstyled text-small">
                        <li><Link to="/team" class="text-muted">Team</Link></li>
                        <li><Link to="/locations" class="text-muted">Locations</Link></li>
                        <li><Link to="/privacy" class="text-muted">Privacy</Link></li>
                        <li><Link to="/terms" class="text-muted">Terms</Link></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <h6 class="text-light">Support</h6>
                    <article><a class="text-muted" href="mailto:erictanatsg@gmail.com">erictanatsg@gmail.com</a></article>
                    <h6 class="text-light">Contact</h6>
                    <Link to="/contact" class="text-muted">Contact Us</Link>
                    <h6 class="text-light">Social Links</h6>
                    <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="horizontal" data-theme="dark" data-vanity="er1csg"><a class="LI-simple-link" href='https://sg.linkedin.com/in/er1csg?trk=profile-badge'>Eric Tan</a></div>
                </div>
            </div>
        </footer>
    
        <script src="https://code.jquery.com/jquery-3.6.0.slim.min.js" integrity="sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI=" crossorigin="anonymous"></script>
        <script src="bootstrap/dist/js/bootstrap.bundle.js"></script>
        <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script>
    </div>
  )
}